<section class="px-16 md:px-24 py-48 md:py-64">
  <div *ngIf="!loginDisplay">
    <p>Please sign-in to see your profile information.</p>
  </div>
  <div class="container-4xl" *ngIf="loginDisplay">
    <h2>Check In</h2>
    <div>
      <p><strong>First Name: </strong> {{profile?.given_name}}</p>
      <p><strong>Last Name: </strong> {{profile?.family_name}}</p>
      <p><strong>Email: </strong> {{profile?.emails[0]}}</p>
    </div>
    <div [formGroup]="checkinForm" id="checkin-form">
      <mat-form-field  appearance="fill">
        <mat-label>Find a Site</mat-label>
        <input type="text"
           placeholder="Site Name"
           aria-label="Site"
           matInput
           formControlName="location"
           (input)="onSiteFormInput()"
           ng-model-options="{debounce: 1000}"
           [matAutocomplete]="autoSite">
        <mat-autocomplete #autoSite="matAutocomplete">

          <mat-option *ngIf="showSiteDropDownSpinner">
            <div class="deferred-select-placeholder-container">
              <span>Loading...</span>
              <mat-spinner class="spinner" diameter="20"></mat-spinner>
            </div>
          </mat-option>
          <mat-option
              *ngFor="let site of sites"
              [value]="site.siteAbbreviation"
              (click)="onFieldEntry()">
            {{site.siteName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Reason for Visit</mat-label>
        <input type="text"
           placeholder="Purpose"
           aria-label="Purpose"
           matInput
           formControlName="reason"
           (input)="onFieldEntry()"
           [matAutocomplete]="autoReason">
        <mat-autocomplete #autoReason="matAutocomplete">
          <mat-option
              *ngFor="let reason of filteredReasons | async"
              [value]="reason"
              (click)="onFieldEntry()">
            {{reason}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Phone Number</mat-label>
        <input type="tel"
            placeholder="Format: 1234567890"
            aria-label="Phone number"
            matInput
            (input)="onFieldEntry()"
            formControlName="phoneNum">
      </mat-form-field>
    </div>
    <p class="de-button-group">
      <de-button id="btn-checkin"
          type="primary"
          size="large"
          [disabled]="submitBtnDisabled"
          [loading]="submitBtnProcessing"
          (click)="onCheckinBtnPress()">
        Check In
      </de-button>
    </p>
    <div class="error-group">
      <mat-error *ngIf="siteMissing">
        Please select a site from the list.
      </mat-error>
      <mat-error *ngIf="siteInvalid">
        Unknown site provided.
      </mat-error>
      <mat-error *ngIf="reasonMissing">
        Please select a reason from the list.
      </mat-error>
      <mat-error *ngIf="reasonInvalid">
        Unkown reason provided.
      </mat-error>
      <mat-error *ngIf="phoneNumMissing">
        Please enter a contact phone number.
      </mat-error>
      <mat-error *ngIf="phoneNumInvalid">
        Please enter exactly 10 digits with no special characters for your phone number.
      </mat-error>
      <mat-error *ngIf="serviceUnavalable">
        Location service is currently unavailable. Please manually enter the location info and checkin.
      </mat-error>
    </div>
  </div>
</section>
