import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CheckinSite } from 'src/app/models/checkin-site.model';
import { Worker } from 'src/app/models/worker.model';
import { TelaxsCallsService } from 'src/app/services/telaxs-calls.service';
import { Observable, Subject } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { CheckinRequest } from 'src/app/models/checkin-request.model';
import { DeSnackbarService } from '@de-electron/electron-angular-components';
import { HttpResponse } from '@angular/common/http';
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { EventMessage, EventType, InteractionStatus } from "@azure/msal-browser";
import { UserProfile } from 'src/app/models/UserProfile';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';


type ProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string
};

@Component({
  selector: 'user-checkin',
  templateUrl: './user-checkin.component.html',
  styleUrls: ['./user-checkin.component.scss']
})
export class UserCheckinComponent implements OnInit {
  profile!: UserProfile;
  private readonly _destroying$ = new Subject<void>();
  private inputSubject: Subject<string> = new Subject();
  sites: CheckinSite[] = [];
  showSiteDropDownSpinner: boolean = false;
  enableCheckInButton: boolean = true;
  reasons = [
    "Planned CRQ",
    "Unplanned Break/Fix",
    "Preventative Maintenance",
    "Facilities - Generator",
    "Facilities - HVAC",
    "Facilities - Other"
  ];

  user: Worker = {
    id: "A1234",
    firstName: "Alice",
    lastName: "InChains",
    lastCheckin: new Date("1999-12-31 11:59 PM"),
    defaultPhoneNum: "1112223456",
    organization: "Telecom",
    notes: null
  };

  loginDisplay = false;

  filteredReasons: Observable<string[]>;

  submitBtnDisabled = true;
  submitBtnProcessing = false;
  serviceUnavalable = false;
  siteMissing = false;
  siteInvalid = false;
  reasonMissing = false;
  reasonInvalid = false;
  phoneNumMissing = false;
  phoneNumInvalid = false;

  siteAbbr: string;
  reason: string;
  phoneNum: string;

  checkinForm = new FormGroup({
    location: new FormControl('', [Validators.required]),
    reason: new FormControl('', [Validators.required]),
    phoneNum: new FormControl('', [Validators.required, Validators.pattern(new RegExp(/^\d{10}$/g))])
  })

  constructor(
    private router: Router,
    private service: TelaxsCallsService,
    private snackbar: DeSnackbarService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService) {
    this.inputSubject
      .pipe(
        debounceTime(300) // Adjust the debounce time (in milliseconds) as needed
      )
      .subscribe((searchTerm: string) => {
        this.service.getSitesBySearchTerm(searchTerm).subscribe(res => {
        console.log('UserCheckinComponent onSiteFormInput() response: ' + JSON.stringify(res));
        const json = res.body.results;
        this.sites = []
      json.forEach((site, index) => {
        let addressParts = site.physical_address.split(' | ');
        let streetAddress: String = addressParts[0];
        let city: String = addressParts[1];
        let state: String = addressParts[2];
        let zip: String = addressParts[3];
        let temp = new CheckinSite();
        temp.city = city;
        temp.state = state;
        temp.streetAddress = streetAddress;
        temp.siteAbbreviation = site.facility;
        temp.siteName = site.name;
        temp.regionName = site.parent.name;
        this.sites.push(temp);
      });
        this.sites = this.sites.slice(0, 19);
        console.log(JSON.stringify(this.sites));
        this.showSiteDropDownSpinner = false;
      }, (error) => {
        this.showSiteDropDownSpinner = false;
        this.submitBtnDisabled = false;
        this.serviceUnavalable = true;
        console.log('UserCheckinComponent onSiteFormInput() error: ' + JSON.stringify(error));
      });
      
    })}

  ngOnInit() {
    if (!!this.user.defaultPhoneNum) {
      // this.checkinForm.controls.phoneNum.setValue(this.user.defaultPhoneNum);
    }
    this.filteredReasons = this.checkinForm.controls.reason.valueChanges.pipe(
      startWith(''),
      map(value => this._filterReasons(value)),
    );
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
      }, (error) => {
        console.log(' UserCheckinComponent', error)
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      }, (error) => {
        console.log(' UserCheckinComponent', error)
      })
    this.getProfile();
    // this.msalBroadcastService.msalSubject$
    // .pipe(
    // 	filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
    // 	takeUntil(this._destroying$)
    // )
    // .subscribe((result: EventMessage) => {
    // 	// Do something with event payload here
    //   console.log('*********UserCheckinComponent msalSubject '+ JSON.stringify(result));
    // });

  }

  getProfile() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None || status === InteractionStatus.HandleRedirect),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
        this.profile = this.authService.instance.getActiveAccount()?.idTokenClaims as UserProfile;
        // this.authorizationService.fetchUserGroupsByEmailAddress(this.profile.emails[0]).subscribe((groups)=>{
        //   console.log('UserCheckinComponent getProfile() groups success: ', groups);
        // },(error)=>{
        //   console.log('UserCheckinComponent getProfile() groups error: ', error);
        // })
        this.checkinForm.controls.phoneNum.setValue(this.profile.extension_PhoneNumber);
        this.phoneNum = this.profile.extension_PhoneNumber;
        console.log('UserCheckinComponent getProfile() parsed profile: ' + JSON.stringify(this.profile));
      }, (error) => {
        console.log(' UserCheckinComponent', error)
      })
  }

  checkAndSetActiveAccount() {

    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  onFieldEntry() {
    // enable the submit button if all fields have values
    this.submitBtnDisabled = !this.checkinForm.controls.location.value
      || !this.checkinForm.controls.reason.value
      || !this.checkinForm.controls.phoneNum.value;

      console.log('UserCheckinComponent onFieldEntry() this.submitBtnDisabled: ' ,this.submitBtnDisabled);
  }

  onSiteFormInput() {
    console.log('UserCheckinComponent onSiteFormInput() serviceUnavalable: ' ,this.serviceUnavalable);

    let term = this.checkinForm.controls.location.value;
    term && term.length >= 2 ? this.showSiteDropDownSpinner = true : this.showSiteDropDownSpinner = false;
    if (term.length > 1) {
      this.inputSubject.next(term);
    }
  }

  onCheckinBtnPress() {
    //this.serviceUnavalable = false;
    this.siteAbbr = this.checkinForm.controls.location.value;
    this.siteMissing = !this.siteAbbr;
    console.log('UserCheckinComponent onCheckinBtnPress() submitBtnDisabled: ' ,this.submitBtnDisabled);

    if(this.serviceUnavalable){

      this.siteInvalid = false;

    }else{

      this.siteInvalid = !this.sites.some(site => site.siteAbbreviation === this.siteAbbr)? !this.sites.some(site => site.siteAbbreviation === this.siteAbbr):false;

    }

    //this.siteInvalid = this.submitBtnDisabled==false? false:!this.sites.some(site => site.siteAbbreviation === this.siteAbbr);
    console.log('UserCheckinComponent onCheckinBtnPress() siteInvalid: ' ,this.siteInvalid);
    this.reason = this.checkinForm.controls.reason.value;

    this.reasonMissing = !this.reason;
    this.reasonInvalid = !this.reasons.includes(this.reason);
    this.phoneNum = this.checkinForm.controls.phoneNum.value;

    this.phoneNumMissing = !this.phoneNum;
    var regex = new RegExp(/^\d{10}$/g);
    this.phoneNumInvalid = !regex.test(this.phoneNum);

    if (!this.siteMissing && !this.siteInvalid && !this.reasonMissing && !this.reasonInvalid && !this.phoneNumMissing && !this.phoneNumInvalid) {
      this.submitBtnProcessing = true;
      let request = new CheckinRequest;
      request.WorkerID = this.profile.id;
      request.FirstName = this.profile.given_name;
      request.LastName = this.profile.family_name;
      request.Organization = "Telecom";
      request.SiteID = this.siteAbbr;
      request.VisitReason = this.reason;
      request.PhoneNumber = this.phoneNum;
      this.service.checkinUser(request).subscribe((res: HttpResponse<any>) => {
        this.submitBtnProcessing = false;
        if (res.ok) {
          this.snackbar.show({
            type: "success",
            text: "Checkin successful!",
            // linkText: "ok",
            // clickEventContent: "text",
            mobilePosition: 'top',
            duration: 3000
          });
          console.log(request);
          this.router.navigateByUrl('/checkins');
        } else {
          this.snackbar.show({
            type: "warning",
            text: "An error occurred during checkin. Please contact the NOC for assistance.",
            // linkText: "ok",
            // clickEventContent: "text",
            mobilePosition: 'top',
            duration: 10000
          });
        }
      })
    }
  }

  private _filterReasons(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.reasons.filter(option => option.toLowerCase().includes(filterValue));
  }

}
